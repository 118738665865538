import {FC} from "react"
import PublicRoute from "./routes/PublicRoute";

import "./components/i18n/I18n";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/Variable.scss";
import "./styles/Style.scss";

const App:FC = () => {
  return (
    <div className="App">
      <PublicRoute />
    </div>
  );
}

export default App;
