import cogoToast from "cogo-toast";

type ToastOptions = {
  position: "top-center" | "top-left" | "top-right" | "bottom-left" | "bottom-center" | "bottom-right";
  heading: string;
};

const showToast = (type: "success" | "error" | "info", message: string, heading: string) => {
  const options: ToastOptions = {
    position: "top-center",
    heading,
  };
  cogoToast[type](message, options);
};

const toast = {
  success: (message: string) => showToast("success", message, "Success"),
  error: (message: string) => showToast("error", message, "Error"),
  info: (message: string) => showToast("info", message, "Alert"),
};

export default toast;
